<template>
    <div class="map-module">
        <iframe :src="config.map.link" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
</template>

<script>

    export default {
        props: ["config"],
        mounted(){
            if(!this.config.map){
                this.config.map = {link: ""};
            }
        }
    };

</script>